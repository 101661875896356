@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bg-col-gr: rgb(239,240,240); /*#EFF0F0*/
  --pm-col-br: rgb(181,69,33); /*#B54521*/
  --pm-col-bl: rgb(0,0,148); /*#000094*/
  --pm-col-ltbr: rgb(219,161,117); /*#dba175*/
  --menu-panel-width: 300px;
  --menu-left-margin: 15px;
  --card-row-bg: rgb(247,247,247);  /*#f7f7f7*/
  --photo-width-size: 290px;
  --bg-gray-col: #efefef;
}

body {
  /* font-family: 'Roboto', sans-serif;
  font-weight: 200; */
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #707172;
  height: 100%;
  scrollbar-width: none;
}

.sidepanel {
  position: relative;
  height: 100vh; 
  scrollbar-width:none;
}

p {
  line-height: 1.8;
}

.app-container {
  display: grid;
  grid-template-columns: auto 1fr;
  overflow: hidden !important;
  height: 100vh;
  width: 100vw;
}

.maincontent {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  -moz-scrollbar-width: none;
  /* scrollbar-width: none;*/  /*Firefox */
  scrollbar-width: none;
}

@-moz-document url-prefix() {
  .maincontent{
      scrollbar-width: none !important;
  }
}

.maincontent::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
  width: 0;
  height: 0;
}

::-webkit-scrollbar {
  display: none;
}

.app-container > div:nth-child(1) {
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: var(--pm-col-bl);
}

.app-container > div:nth-child(2) {
  background-color: var(--bg-col-gr);
  width: 100%;
  height: 100%;
}

.sidepanel {
  width: var(--menu-panel-width);
  opacity: 0.8;
  z-index: 2;
  position: absolute;
  transition: .3s;
  left: 0;
}
/* .show {
  left: 0;
}
.hide {
  left: calc(0px - var(--menu-panel-width));
} */
.sidepanel-sticky {
  overflow-y: scroll;
  height: 100%;
}
.openbtn {
  background-color: #000;
  color: #fff;
  left: var(--menu-panel-width);
  top: 10px;
  margin-left: 4px;
  position: absolute;
  border: none;
  height: 50px;
  width: 50px;
  font-size: 22px;
  display: none;
  transition: .8s;
}

.sidepanel a {
  color: #fff;
  text-decoration: none;
  transition: .3s;
}

footer {
  border: 0;
  color: #fff;
  margin: 50px 0 0 var(--menu-left-margin);
}

.bannervid {
  /* min-width: 100%;
  min-height: 100%; */
  object-fit: fill;
}

.home-right-panel {
  height: 100%;
  width: 270px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  background-image: url('./assets/images/rbanner.png');
  background-size: 100% 100%;
  transition: .3s;
}

.main-content-elements {
  width: calc(100vw - var(--menu-panel-width));
  margin-left: var(--menu-panel-width);
}


.main-content-elements > div {
  /* overflow-y: scroll; */
}

.text-uppercase {
  text-transform: uppercase !important;
}
.site-contact-num-sec {
  margin: 10px 15px;
}
.site-contact-num-sec span {
  display: block;
  color: #fff;
  margin-bottom: 10px;
}
.site-logo-sec {
  width: calc(100% - 30px);
  height: 132px;
  margin: 20px auto;
  background-image: url('./assets/images/PrimusLogoWithBg550x200.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.nav {
  list-style: none;
  margin: 0 var(--menu-left-margin);
  display: flex;
  flex-direction: column;
}

.tm-main-nav {
  margin-bottom: 20px;
}

.tm-main-nav li {
  list-style: none;
  display: block;
  padding: 5px 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  cursor: pointer;
}

.flex-row .nav-item {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row .nav-item a {
  color: var(--pm-col-br);
  font-size: 24px;
  line-height: 1.2px;
}

.sub-page-headers {
  width: calc(100vw - var(--menu-panel-width));
  height: 250px;
  transition: .3s;
}

.about-pg {
  background-image: url('./assets/images/PrimusBannerRd1200x250Boxes.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.services-pg {
  background-image: url('./assets/images/PrimusBannerRd1200x250Warehouse.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.sub-page-content {
  background-image: url('./assets/images/pmbg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: auto;
}

.sub-page-content > section {
  height: 400px;
  margin-bottom: 40px;
  background-color: var(--card-row-bg);
  transition: .3s;
}

/* Map section */
.mapouter{
  position:relative;
  text-align:right;
  height:400px;
  width:100%;
}

.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:400px;
  width:100%;
}
/* End Map section */

/* Destinations section */
.row-destinations {
  display: grid;
  grid-template-columns: repeat(6,1fr);
  column-gap: 20px;
  row-gap: 10px;
}
/* End Destinations section */

.pg-card-row {
  padding: 10px;
}

.sub-page-content > section {
  padding: 20px;
  height: auto;
}

.pm-section-abt {
  display: grid;
  grid-template-columns: 1fr var(--photo-width-size);
  justify-content: stretch;
  align-items: stretch;
  gap: 30px;
}

.pm-jo {
  background-image: url('./assets/images/James.jpeg');
  background-size: 100% 100%;
  background-color: #707172;
  height: 380px;
  transition: .3s;
}

.sub-page-content > section:nth-child(2) {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pm-section-ouv {
  width: calc(100% - 80px);
  display: flex;
  column-gap: 40px;
}

.pm-ja {
  flex-basis: var(--photo-width-size);
  background-color: #fff;
  border-radius: 20px;
  height: 400px;
  background-image: url('./assets/images/John.jpeg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: .3s;
}

.pm-shared-partners {
  display: none;
}

.pm-shared-partners > div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
}

.pm-shared-partners > div > div {
  flex: 1;
}


.pm-section-ouv > div:nth-child(3) {
  flex: 1;
  background-color: #fff;
  border-radius: 20px;
  height: auto;
  padding: 40px;
}

ul li {
  list-style: none;
  
}
ul li span div {
  height: 15px;
  width: 15px;
  background-color: #bf692b;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.row-destinations {
  display: grid;
  grid-template-columns: repeat(6,1fr);
  column-gap: 20px;
  row-gap: 10px;
}

.row-destinations div {
  background-color: #ffffff;
  padding: 5px 10px;
  border-left: 1px solid #dba175;
}

.tm-section-title {
  font-size: 1.6rem;
}

.tm-color-primary {
  color: #b54521;
}

/*Services section*/
.photo {
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.photo img {
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}

.photo img:hover {
  transform: scale(1.2);
}


/* Modal section */
.modal { padding-left: 15px;}
.modal-body.modal-body-img {
  overflow: hidden;
  height: 150px;
  width: 280px;
}

#service-single-modal-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/*.modal-body.modal-body-img img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}*/

.modal-body-description {
  max-height: 200px;
  overflow-y: auto;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.btn-danger {
  background-color: #b54520;
  border-color: #b54520;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(181,69,33,.15) !important;
}

.card {
  border: 1px solid rgba(181,69,33,.15);
}

.services-details {
  background-color: #f7f7f7;
}

.pm-services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: stretch;
  align-items: stretch;
  gap: 0.8rem;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(181,69,33,.15) !important;
}
/*End Services section*/

/*Clients section*/
.tm-img-wrap a {
  background: #ffffff !important;
  padding: 0 !important;
}

.tm-clients-images {
  max-width: 1040px;
}
.tm-clients-images {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 auto;
}
.tm-img-wrap {
  background-color: #fff;
  width: 240px;
  height: 120px;
}
/*End Clients section*/


/*Contact section*/
.contact-pg.contact-pg {
  background-image: url('./assets/images/ContactBgs.jpg');
  background-size: cover;
  height: auto; /*650px*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-pg > div {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.contact-pg > div:nth-child(1) {
  flex: 2;
  padding: 50px;
  color: #fff;
}

.contact-pg > div:nth-child(2) {
  flex-basis: 300px;
}
.contact-desc-panel p {
  text-align: right;
  font-size: 20px;
}
.contact-desc-panel h1 {
  font-size: 38px;
  font-weight: 700;
  text-align: right;
}
.contact-form {
  height: auto;
  width: 100%;
  border: 2px solid #fff;
  transition: .3s;
}

.contact-form div {
  margin: 15px 20px;
}
.form-group {
  display: flex;
}
.form-group > input, 
.form-group > textarea {
  padding: 5px 10px;
  flex: 1;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}

.contact-btn {
  background-color: var(--pm-col-ltbr);
  color: #fff;
  height: 50px;
  width: 140px;
}

.contact-btn span {
  margin-right: 10px;
  font-size: 24px;
}

.sub-page-content-elements {
  background-color: var(--bg-col-gr) !important;
  height: auto;
  transition: .3s;
}

.sub-page-content-elements > div {
  padding: 20px 40px;
}

.pg-countries-details {
  display: flex;
  column-gap: 40px;
}

.pg-countries-details > div {
  flex: 1;
  
}

/*End Contact section*/

/*Fleet section*/
.fleet-pg {
  background-color: var(--bg-gray-col);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.fleet-pg > div {
  width: 1000px;
  display: flex;
  transition: .3s;
}

.fleet-pg > div > section {
  width: 250px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: .3s;
}

.fleet-pg > div > section:nth-child(1) {
  background-image: url('./assets/images/Fleets/Mbr1.jpg');
}

.fleet-pg > div > section:nth-child(2) {
  background-image: url('./assets/images/Fleets/Mbr2.jpg');
}

.fleet-pg > div > section:nth-child(3) {
  background-image: url('./assets/images/Fleets/Mbr3.jpg');
}

.fleet-pg > div > section:nth-child(4) {
  background-image: url('./assets/images/Fleets/Mbr4.jpg');
}

.fleet-main-content {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fleet-main-content > p {
  width: 80%;
  text-align: center;
  font-size: 24px;
  margin: 40px 0 60px 0;
}
.fleet-details {
  display: flex;
  height: auto;
  width: 80%;
}
.fleet-section-left {
  flex-basis: 28%;
}

.fleet-section-left > section {
  background-color: var(--bg-gray-col);
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.fleet-section-center {
  background-image: url('./assets/images/mbr-982x655.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  flex-basis: 44%;
}
.fleet-section-right {
  flex-basis: 28%;
}

.fleet-section-right > section {
  background-color: var(--bg-gray-col);
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.fleet-details div section h5 {
  font-weight: bold;
}

.fleet-section-left section h5,
.fleet-section-left section p {
  text-align: right;
}

.fleet-section-right section h5,
.fleet-section-right section p {
  text-align: left;
}

/*End Fleet section*/


/*Slick Slider section*/
img {
  width: 100%;
}
.container {
  max-width: 1040px;
  margin: 100px auto;
}
h1 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  position: relative;
  margin-bottom: 60px;
}
h1:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 4px;
  background-color: #ff8159;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.logo-slider .item {
  background-color: #fff;
  box-shadow: 0 4px 5px #cacaca;
  border-radius: 8px;
  padding: 15px;
  border: 2px solid #111;
}
.logo-slider .slick-slide {
  margin: 15px
}
.slick-dots li.slick-active button::before {
  color: #ff5722;
}
.slick-next::before,
.slick-prev::before {
  color: #ff8159;
  font-size: 24px;
}
.item:hover {
  display: block;
  transition: all eas 0.3s;
  transform: scale(1.1) translateY(-5px);
}

/*End Slick Slider section*/




@media (max-width: 1300px) {
  .fleet-pg > div > section {
    width: 200px;
    height: 120px;
  }
  .fleet-pg > div {
    width: 800px;
  }

  .fleet-pg {
    height: 150px;
  }
}

@media (max-width: 1100px) {
  .fleet-pg > div > section {
    width: 150px;
    height: 80px;
  }
  .fleet-pg > div {
    width: 600px;
  }

  .fleet-pg {
    height: 100px;
  }

  .sub-page-headers {
    height: 200px;
  }
}

@media (max-width: 1000px) {
  .sidepanel {
    left: calc(0px - var(--menu-panel-width));
  }

  .sub-page-headers {
    width: 100vw;
  }

  .main-content-elements {
    width: 100vw;
    margin-left: 0;
  }

  .openbtn {
    display: block;
  }
}

@media (max-width: 870px) {
  .pm-section-abt {
    display: block;
  }
  .pm-section-abt > .pm-jo,
  .pm-section-ouv > .pm-ja {
      display: none;
  }

  .pm-ja,
  .pm-jo {
    height: 400px;
    border-radius: 20px;
  }

  .pm-shared-partners {
    display: block;
  }

  .row-destinations {
    grid-template-columns: repeat(4,1fr);
  }

  .pm-section-ouv {
    column-gap: 0;
    row-gap: 40px;
    flex-direction: column;
  }

  .pm-section-ouv > div:nth-child(1) {
    height: 450px;
  }

  .pm-services-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
  }

  .fleet-details {
    display: block;
  }
  .fleet-section-left {
    display: flex;
    column-gap: 20px;
  }
  .fleet-section-center {
    height: 200px;
    width: 300px;
    margin: 0 auto;
  }
  .fleet-section-right {
    display: flex;
    column-gap: 20px;
  }
  .fleet-section-right > section,
  .fleet-section-left > section {
    flex: 1;
    background-color: var(--bg-gray-col);
    padding: 10px 20px;
    border-radius: 5px;
  }

  .fleet-section-right > section:first-child > h5,
  .fleet-section-right > section:first-child > p,
  .fleet-section-left > section:first-child > h5,
  .fleet-section-left > section:first-child > p { 
    text-align: left;
  }

  .fleet-section-right > section:last-child > h5,
  .fleet-section-right > section:last-child > p,
  .fleet-section-left > section:last-child > h5,
  .fleet-section-left > section:last-child > p { 
    text-align: right;
  }

  .sub-page-headers {
    height: 150px;
  }

  .home-right-panel {
    width: 210px;
  }
}

@media (max-width: 620px) {
  .pm-services-container {
    display: block;
  }
  .pm-services-container .col {
    margin-bottom: 40px;
  }

  .pm-ja,
  .pm-jo {
    height: 300px;
  }

  .pm-section-ouv > div:nth-child(1) {
    height: auto;
  }

  .sub-page-content > section {
    padding: 0;
  }

  .pm-section-abt > div:first-child,
  .sub-page-content > section:last-child {
    padding: 20px 40px;
  }
  
  .contact-pg > div {
    display: block;
  }
  .contact-desc-panel {
    /* margin-top: 200px; */
  }
  .contact-desc-panel > h1,
  .contact-desc-panel > p {
    text-align: center;
  }
}

@media (max-width: 520px) {
  .pm-shared-partners > div {
    display: block;
  }

  .pm-ja,
  .pm-jo {
    height: 320px;
    width: 280px;
    margin: 0 auto;
  }

  .pm-jo {
    margin-bottom: 40px;
  }

  .row-destinations {
    grid-template-columns: repeat(3,1fr);
  }

  .pg-countries-details {
    display: block;
  }
  .pg-countries-details .ghanabr {
    margin-bottom: 40px;
  }
}